import { useEffect } from 'react'
import { sendGTMEvent } from '@next/third-parties/google'
import { useLocale } from 'next-intl'
import * as z from 'zod'
import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'
import { ConversionSchema, Locale } from '@/types'
import { useConversion } from '@/hooks/use-conversion'
import { postLead } from '@/services/lead'

export type useConversionFormProps = {
  field: Messages['forms']['field']
  validation: Messages['forms']['validation']
  util: Messages['util']
  conversion: Messages['properties']['conversion']
  send: string
}

export const useConversionForm = (props: useConversionFormProps) => {
  const { validation, field, util, send, conversion } = props
  const { opened, closeForm, reference } = useConversion()
  const locale = useLocale()

  const schema = z.object({
    name: z.string().min(1, { message: validation.required }),
    email: z
      .string()
      .min(1, { message: validation.required })
      .email({ message: validation.invalid_email }),
    phone: z.string().min(1, { message: validation.required }),
    message: z.string()
  })

  const { register, handleSubmit, clearErrors, formState, reset } =
    useForm<ConversionSchema>({
      resolver: zodResolver(schema)
    })

  const onSubmit: SubmitHandler<ConversionSchema> = async (data) => {
    if (!reference) return

    try {
      const response = await postLead({
        locale: locale as Locale,
        data: {
          ...data,
          reference
        }
      })

      if (response?.message) {
        sendGTMEvent({
          event: 'quero_este_imovel',
          value: `imovel-${reference}`
        })

        toast.success(response.message)
        reset()
        closeForm()
      }
    } catch (error: any) {
      if (error?.params?.body?.message) {
        toast.error(error.params.body.message)
      }
    }
  }

  useEffect(() => {
    if (opened) {
      clearErrors()
    }
  }, [clearErrors, opened])

  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    formState,
    field,
    send,
    util,
    conversion,
    opened,
    closeForm
  }
}
