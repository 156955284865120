import { SvgName } from '@/svg'
import { getWhatsAppLink } from '@/utils/whatsapp'
import { ButtonProps } from '@/components/ui/button'

export type MenuItemProps = {
  title: keyof Messages['menu']
  href: string
  icon?: SvgName
  target?: '_blank' | '_self'
  active?: string[]
  dropdown?: {
    icon: SvgName
    title: keyof Messages['services']
    href: string
  }[]
} & Pick<ButtonProps, 'variant' | 'color' | 'fullWidth'>

export const menu: MenuItemProps[] = [
  {
    variant: 'link',
    title: 'rent',
    href: '/imoveis?finalidade=alugar',
    active: ['imoveis?finalidade=alugar']
  },
  {
    variant: 'link',
    title: 'purchase',
    href: '/imoveis?finalidade=venda',
    active: ['imoveis?finalidade=venda']
  },
  {
    variant: 'link',
    title: 'releases',
    href: '/imoveis?categoria=lancamentos',
    active: ['imoveis?categoria=lancamentos']
  },
  {
    variant: 'link',
    title: 'city',
    href: '/onde-morar/joao-pessoa',
    active: ['onde-morar']
  },
  {
    variant: 'link',
    title: 'services',
    href: '#',
    active: ['anuncie-seu-imovel', 'experiencias', 'preço-m2'],
    dropdown: [
      {
        icon: 'house-dollar',
        title: 'advertise',
        href: '/anuncie-seu-imovel'
      },
      {
        icon: 'earth-pin',
        title: 'live',
        href: '/onde-morar'
      },
      {
        icon: 'experiences',
        title: 'experiences',
        href: '/experiencias'
      },
      {
        icon: 'calculator',
        title: 'calculator',
        href: '/preco-m2/joao-pessoa'
      }
    ]
  },
  {
    variant: 'link',
    title: 'blog',
    href: '/blog',
    active: ['blog']
  },
  {
    variant: 'link',
    title: 'helpdesk',
    href: '/central-de-ajuda',
    active: ['central-de-ajuda', 'fale-conosco']
  },
  {
    variant: 'link',
    title: 'about',
    href: '/institucional',
    active: ['institucional']
  },
  {
    variant: 'link',
    title: 'work_us',
    href: '/trabalhe-conosco',
    active: ['trabalhe-conosco']
  },
  {
    variant: 'outline',
    color: 'primary',
    title: 'advertise',
    href: '/anuncie-seu-imovel',
    fullWidth: 'mobile'
  },
  {
    variant: 'solid',
    color: 'primary',
    title: 'talk',
    href: getWhatsAppLink({ phone: 'default', message: 'default' }),
    target: '_blank',
    icon: 'social-whatsapp',
    fullWidth: 'mobile'
  }
]
